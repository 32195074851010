import moment, { type Moment } from "moment";
import type { NamespacePortfolioOpex, NamespacePortfolioPropertyOpex } from "../user-preferences-helpers";
import { type CategoryCell, fitDateString, getDateRange, getDateRangeEntries } from "./portfolio-balance-helpers";
import { Currency } from "../apollo/types";
import { arrayLast, hasValue } from "../common-helpers";
import type { OpexData } from "~/composables/queries/useOpexQuery";
import { AssetManagementPropertySubType, MetricPeriodType } from "~/graphql/generated/graphql";
import { getCategories as getCategoriesBase, type Budget, type CategoryRow } from "./portfolio-category-helpers";
import { Category, getPropertyArea, TenanciesEntity } from "./portfolio-property-helpers";
import { PortfolioPropertyOpexCategoryData } from "~/pages/portfolio/components/Expenses/Opexes/helpers";

export type OpexCategoryDataPointValue = { accumulated: number; accumulatedAnnualised: number; value: number; valueAnnualised: number };

export type OpexCategoryBenchmarkDataPoint = {
  target: OpexCategoryDataPointValue;
  benchmark: OpexCategoryDataPointValue;
  diff: OpexCategoryDataPointValue;
  diffPercentage: OpexCategoryDataPointValue;
};

export type OpexCategoryData = { id: string; name: string; currency: Currency; values: OpexCategoryDataPointValue[]; accountHashes: string[] };

export type OpexCategoryBenchmarkData = {
  id: string;
  name: string;
  currency: Currency;
  accountHashes: string[];
  target: OpexCategoryDataPointValue[];
  benchmark: OpexCategoryDataPointValue[];
  diff: OpexCategoryDataPointValue[];
  diffPercentage: OpexCategoryDataPointValue[];
};

export const getOpexCategoryChartColumns = (preferences: NamespacePortfolioPropertyOpex, budget?: Budget) => {
  const withBudget = false;
  const periodType = withBudget ? MetricPeriodType.Monthly : preferences.periodType;

  const dateRange = getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);

  return getDateRangeEntries(dateRange, periodType);
};

export const getOpexCategoryBenchmarkData = (
  columns: Moment[],
  target: OpexData,
  benchmark: OpexData,
  preferences: { periodType: MetricPeriodType; normaliseBy: "area" | "tenancies"; includeZeroRows?: boolean },
  categories: Category[],
  includeTotal = false
) => {
  const periodType = preferences.periodType;

  const periodTypeMonthCount = periodType === MetricPeriodType.Monthly ? 1 : periodType === MetricPeriodType.Quarterly ? 3 : 12;

  const normaliseByField: keyof OpexData["entityData"] = preferences.normaliseBy === "area" ? "totalArea" : "totalTenancies";

  const normaliseAverage = preferences.normaliseBy === "area" ? benchmark.entityData.averageArea : benchmark.entityData.averageTenancies ?? 1;

  const getMonthDiff = (index: number) => Math.abs(columns[0].diff(columns[index], "months")) + periodTypeMonthCount;

  const getCategories = (data: CategoryCell[]) => getCategoriesBase(data, columns, new Map(), periodType, true, "results");

  const getOpexDataRow = (categoryRow: CategoryRow): OpexCategoryData => ({
    id: categoryRow.id,
    currency: categoryRow.currency,
    name: categoryRow.name,
    accountHashes: categoryRow.hashes,
    values: categoryRow.values.map((v) => {
      const value = v.totalCostWithVat;

      return {
        value,
        accumulated: 0,
        accumulatedAnnualised: 0,
        valueAnnualised: 0,
      };
    }),
  });

  /** Normalises a category row by selected unit + create annualised value */
  const normaliseRow = (row: OpexCategoryData, data: OpexData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];

      current.value /= data.entityData[normaliseByField] || normaliseAverage || 1;

      current.valueAnnualised = current.value * (12 / periodTypeMonthCount);
    }
  };

  /** Accumulates column values for a category row + the amount of 12 month periods of current accumulation. Will extrapolate if less than 12 */
  const accumulateRow = (row: OpexCategoryData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];
      const prev = row.values[i - 1];

      const monthDiff = getMonthDiff(i);

      const annualiseFactor = 12 / monthDiff;

      current.accumulated = current.value + (prev?.accumulated ?? 0);

      current.accumulatedAnnualised = current.accumulated * annualiseFactor;
    }
  };

  const categoryIds = new Set(categories.map((c) => c.id));

  /** Iterate through all "cells" which hold accumulated data for a category in period  */
  const getCategoryRows = (data: OpexData) => {
    data = { ...data, cells: data.cells?.filter((c) => categoryIds.has(c.categoryId)) };

    const rows = data.cells ? getCategories(data.cells).map((row) => getOpexDataRow(row)) : [];

    if (includeTotal) {
      rows.push({
        currency: rows[0]?.currency ?? "DKK",
        id: "total",
        name: "Total",
        accountHashes: [],
        values: rows.reduce((prev, next) => {
          for (let i = 0; i < next.values.length; i++) {
            if (!hasValue(prev[i])) prev[i] = { accumulated: 0, accumulatedAnnualised: 0, value: 0, valueAnnualised: 0 };

            prev[i].value += next.values[i].value;
          }

          return prev;
        }, [] as OpexCategoryDataPointValue[]),
      });
    }

    rows.forEach((row) => normaliseRow(row, data));

    rows.forEach(accumulateRow);

    return rows;
  };

  const targetRows = getCategoryRows(target);
  const benchmarkRows = getCategoryRows(benchmark);

  /** Merging rows for benchmark */

  /** This is where most of the math happens */
  const getBenchmarkDataPoint = (
    benchmarkCategoryValue: OpexCategoryDataPointValue = { accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 },
    targetCategoryValue: OpexCategoryDataPointValue = { accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 }
  ): OpexCategoryBenchmarkDataPoint => {
    const getDiffPercentage = (diff: number, total: number) => Math.sign(diff) * Math.abs((diff * 100) / total);

    const target = { ...targetCategoryValue };
    const benchmark = { ...benchmarkCategoryValue };
    const diff = {
      accumulated: target.accumulated - benchmark.accumulated,
      accumulatedAnnualised: target.accumulatedAnnualised - benchmark.accumulatedAnnualised,
      value: target.value - benchmark.value,
      valueAnnualised: target.valueAnnualised - benchmark.valueAnnualised,
    };
    const diffPercentage = {
      accumulated: getDiffPercentage(diff.accumulated, benchmark.accumulated),
      accumulatedAnnualised: getDiffPercentage(diff.accumulatedAnnualised, benchmark.accumulatedAnnualised),
      value: getDiffPercentage(diff.value, benchmark.value),
      valueAnnualised: getDiffPercentage(diff.valueAnnualised, benchmark.valueAnnualised),
    };

    return { benchmark, diff, diffPercentage, target };
  };

  const benchmarkDataRows: OpexCategoryBenchmarkData[] = [];

  for (let i = -1; i < categories.length; i++) {
    if (i === -1 && !includeTotal) continue;

    const category = i === -1 ? { id: "total", name: "Total" } : categories[i];

    const benchmarkRow = benchmarkRows.find((c) => c.id === category.id);
    const targetRow = targetRows.find((c) => c.id === category.id);

    const targetRowValues: OpexCategoryDataPointValue[] = [];
    const benchmarkRowValues: OpexCategoryDataPointValue[] = [];
    const diffRowValues: OpexCategoryDataPointValue[] = [];
    const diffPercentageRowValues: OpexCategoryDataPointValue[] = [];

    for (let j = 0; j < columns.length; j++) {
      const { benchmark, diff, diffPercentage, target } = getBenchmarkDataPoint(benchmarkRow?.values[j], targetRow?.values[j]);

      targetRowValues.push(target);
      benchmarkRowValues.push(benchmark);
      diffRowValues.push(diff);
      diffPercentageRowValues.push(diffPercentage);
    }

    benchmarkDataRows.push({
      currency: (benchmarkRow?.currency ?? targetRow?.currency)!,
      id: category.id,
      name: category.name ?? "",
      accountHashes: targetRow?.accountHashes ?? [],
      benchmark: benchmarkRowValues,
      diff: diffRowValues,
      diffPercentage: diffPercentageRowValues,
      target: targetRowValues,
    });
  }

  benchmarkDataRows.sort((a, b) => (a.id === "total" ? 1 : a.name.localeCompare(b.name)));

  return benchmarkDataRows;
};

export const benchmarkOptionGroups = [
  {
    id: "average",
    options: [
      {
        id: "average.total",
      },
    ],
  },
  {
    id: "budget",
    options: [
      {
        id: "budget.diff",
      },
    ],
  },
] as const;

export type BenchmarkGroup = (typeof benchmarkOptionGroups)[number];
export type BenchmarkGroupOption = BenchmarkGroup["options"][number]["id"];

export const getPropertiesAverageBenchmark = (
  properties: (TenanciesEntity & { subType: AssetManagementPropertySubType; id: string; categoryCells?: Nullable<CategoryCell[]> })[]
) => {
  const benchmarkOpexData: OpexData = {
    cells: properties.reduce((prev, next) => {
      prev.push(...(next.categoryCells as CategoryCell[]));
      return prev;
    }, [] as CategoryCell[]),
    entityData: properties.reduce(
      (prev, next) => {
        const totalTenancies = next.assetManagementTenancies?.items?.length ?? 0;
        const totalArea = getPropertyArea(next);

        prev.totalArea += totalArea.area;
        prev.totalTenancies += totalTenancies;

        if (!prev.areaUnit) prev.areaUnit = totalArea.areaUnit;

        return prev;
      },
      { areaUnit: "", averageArea: 0, totalArea: 0, totalTenancies: 0 }
    ),
  };

  const propertyCount = properties.filter((r) => r.subType === AssetManagementPropertySubType.Property).length;

  benchmarkOpexData.entityData.averageArea = propertyCount ? benchmarkOpexData.entityData.totalArea / propertyCount : 1;
  benchmarkOpexData.entityData.averageTenancies = propertyCount ? benchmarkOpexData.entityData.totalTenancies / propertyCount : 1;

  return benchmarkOpexData;
};

export const getPropertyBudgetBenchmark = (
  property: { id: string } & TenanciesEntity,
  categoryIds: Set<string>,
  budgets: Budget[],
  propertiesAverage: OpexData
) => {
  const totalTenancies = property.assetManagementTenancies?.items?.length ?? 0;
  const totalArea = getPropertyArea(property);
  const cells: CategoryCell[] = [];

  const records = budgets.filter((b) => b.propertyId === property.id).flatMap((b) => b.assetManagementBudgetRecords?.items) ?? [];

  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    const account = record?.assetManagementAccount;
    const category = account?.assetManagementCategory;

    if (!account || !category || !categoryIds.has(category.id)) continue;

    const cell: CategoryCell = {
      categoryId: category.id,
      categoryLevel: 0,
      categoryName: category.name ?? "",
      change: 0,
      changePercentage: 0,
      currency: record.currency ?? "DKK",
      hash: "",
      isBlueprint: false,
      order: 0,
      period: record.entryDate,
      totalCostWithVat: -1 * (record.actualCostWithVat ?? 0),
      parentCategoryId: category.parentCategoryId,
    };

    cells.push(cell);
  }

  const area = totalArea.area || propertiesAverage.entityData.averageArea;

  const benchmarkOpexData: OpexData = {
    cells,
    entityData: {
      areaUnit: totalArea.areaUnit,
      averageArea: area,
      totalArea: area,
      totalTenancies: totalTenancies,
      averageTenancies: totalTenancies,
    },
  };

  return benchmarkOpexData;
};

export const isZeroOpexRow = (data: PortfolioPropertyOpexCategoryData, periodRangeType: NamespacePortfolioOpex["periodRangeType"]) => {
  const columns = Array.from(data.propertiesData.values());

  const roundedValue = (v?: OpexCategoryDataPointValue) => Math.round(((periodRangeType === "yearWhole" ? v?.value : v?.accumulated) ?? 0) * 1000) / 1000;

  return columns.every((c) => roundedValue(arrayLast(c.benchmark)) === 0 && roundedValue(arrayLast(c.target)) === 0);
};
