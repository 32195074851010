import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.function.name.js";
import { useQuery } from "@vue/apollo-composable";
import { OpexCategoriesDocument } from "~/graphql/generated/graphql";
var flatMapTree = function flatMapTree(categories) {
  var flat = [];
  for (var i = 0; i < categories.length; i++) {
    var _childCategories$item;
    var _categories$i = categories[i],
      id = _categories$i.id,
      name = _categories$i.name,
      childCategories = _categories$i.childCategories;
    flat.push({
      id: id,
      name: name
    });
    flat.push.apply(flat, _toConsumableArray(flatMapTree((_childCategories$item = childCategories === null || childCategories === void 0 ? void 0 : childCategories.items) !== null && _childCategories$item !== void 0 ? _childCategories$item : [])));
  }
  return flat;
};
export var useOpexCategoriesQuery = function useOpexCategoriesQuery() {
  var opexCategoriesQuery = useQuery(OpexCategoriesDocument);
  var opexCategories = computed(function () {
    var _opexCategoriesQuery$, _opexCategoriesQuery$2;
    var arr = flatMapTree((_opexCategoriesQuery$ = (_opexCategoriesQuery$2 = opexCategoriesQuery.result.value) === null || _opexCategoriesQuery$2 === void 0 ? void 0 : _opexCategoriesQuery$2.assetManagementCategories.items) !== null && _opexCategoriesQuery$ !== void 0 ? _opexCategoriesQuery$ : []);
    return arr;
  });
  return {
    result: opexCategories,
    loading: computed(function () {
      return opexCategoriesQuery.loading.value;
    })
  };
};