import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import { AssetManagementCategoryType, MetricPeriodType, ResultsOpexBudgetRecordsDocument, ResultsOpexPropertiesDocument } from "~/graphql/generated/graphql";
import { getDateConfigRange, getDateRangeEntries } from "~/helpers/portfolio/portfolio-balance-helpers";
import moment from "moment";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { budgetValidRangeFilter, excludePropertyFromResultsFilter, excludeTenancyFromResultsFilter, PropertyDecomissionedFilter, propertyExcludeIdsFilter, propertyPortfolioFilter, TenancyDecomissionedFilter, TenancySoldFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useQuery } from "@vue/apollo-composable";
import { getSetValue } from "~/helpers/portfolio/portfolio-category-helpers";
import { getOpexCategoryBenchmarkData, getPropertiesAverageBenchmark, getPropertyBudgetBenchmark, isZeroOpexRow } from "~/helpers/portfolio/portfolio-opex-helpers";
import { getPropertyArea } from "~/helpers/portfolio/portfolio-property-helpers";
import { useOpexCategoriesQuery } from "./useOpexCategoriesQuery";
export var useOpexData = function useOpexData(preferences, variables) {
  var areaUnit = ref();
  var currency = ref();
  var enabled = computed(function () {
    return !!variables.value.portfolioId || !!variables.value.categoryId && !!variables.value.propertyId;
  });
  var excludePropertyIds = computed(function () {
    return preferences.value.excludePropertyIds;
  });
  var periodType = computed(function () {
    switch (preferences.value.periodRangeType) {
      case "yearWhole":
        return MetricPeriodType.Yearly;
      case "yearRolling":
      case "yearToDate":
        return MetricPeriodType.Monthly;
    }
  });
  var dateRange = computed(function () {
    switch (preferences.value.periodRangeType) {
      case "yearWhole":
        return getDateConfigRange({
          customDateEnd: undefined,
          customDateStart: undefined,
          dateRange: variables.value.propertyId ? "latest5" : "previousYear",
          periodType: periodType.value
        });
      case "yearRolling":
        return getDateConfigRange({
          customDateEnd: undefined,
          customDateStart: undefined,
          dateRange: "latest12",
          periodType: periodType.value
        });
      case "yearToDate":
        var start = moment.utc().startOf("year");
        var end = moment.utc().subtract(1, "month");
        if (end.isBefore(start)) end = moment.utc(start);
        return getDateConfigRange({
          customDateStart: start.format("YYYY-MM-DD"),
          customDateEnd: end.format("YYYY-MM-DD"),
          dateRange: "custom",
          periodType: periodType.value
        });
    }
  });
  var offsetDate = computed(function () {
    return function (date) {
      switch (preferences.value.periodRangeType) {
        case "yearWhole":
          return moment.utc(date).add(preferences.value.periodOffset, "y");
        case "yearRolling":
        case "yearToDate":
          return date;
      }
    };
  });
  var from = computed(function () {
    return offsetDate.value(dateRange.value.from);
  });
  var to = computed(function () {
    return offsetDate.value(dateRange.value.to);
  });
  var columns = computed(function () {
    return getDateRangeEntries({
      from: from.value,
      to: to.value
    }, periodType.value);
  });
  var isBudgetBenchmark = computed(function () {
    var _preferences$value;
    switch ((_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : _preferences$value.benchmark) {
      case "average.total":
        return false;
      case "budget.diff":
        return true;
    }
  });
  var benchmarkPropertyIdFilter = function benchmarkPropertyIdFilter() {
    return !!isBudgetBenchmark.value && !!variables.value.propertyId ? {
      matches: [{
        id: [Operator.Equals, variables.value.propertyId]
      }]
    } : null;
  };
  var propsOpexFilters = useQueryFilters({
    budgetFilters: [budgetValidRangeFilter(computed(function () {
      return {
        from: from.value,
        to: to.value
      };
    }))],
    propertyFilters: [propertyPortfolioFilter(variables), propertyExcludeIdsFilter(computed(function () {
      return {
        excludeIds: excludePropertyIds.value
      };
    })), benchmarkPropertyIdFilter, PropertyDecomissionedFilter, excludePropertyFromResultsFilter],
    tenancyFilters: [TenancySoldFilter, TenancyDecomissionedFilter, excludeTenancyFromResultsFilter]
  });
  var propertiesOpexQuery = useQuery(ResultsOpexPropertiesDocument, function () {
    return {
      propertyFilters: propsOpexFilters.value.value.propertyFilters,
      tenancyFilters: propsOpexFilters.value.value.tenancyFilters,
      budgetFilters: propsOpexFilters.value.value.budgetFilters,
      input: {
        categoryType: AssetManagementCategoryType.Opex,
        periodType: periodType.value,
        from: from.value,
        to: to.value
      },
      includeBudgets: !!isBudgetBenchmark.value
    };
  }, function () {
    return {
      enabled: enabled.value
    };
  });
  var properties = computed(function () {
    var _propertiesOpexQuery$, _propertiesOpexQuery$2;
    return (_propertiesOpexQuery$ = (_propertiesOpexQuery$2 = propertiesOpexQuery.result.value) === null || _propertiesOpexQuery$2 === void 0 ? void 0 : _propertiesOpexQuery$2.assetManagementProperties.items) !== null && _propertiesOpexQuery$ !== void 0 ? _propertiesOpexQuery$ : [];
  });
  var budgetIds = computed(function () {
    var ids = new Set();
    var yearsCovered = new Set();
    columns.value.forEach(function (period) {
      var year = period.year();
      if (yearsCovered.has(year)) return;
      yearsCovered.add(year);
      properties.value.forEach(function (property) {
        var _property$assetManage, _property$assetManage2, _property$assetManage3;
        /**Note Sven: This needs to be changed if we want to handle budgets not conforming to a year */
        var budgets = (_property$assetManage = (_property$assetManage2 = property.assetManagementBudgets) === null || _property$assetManage2 === void 0 ? void 0 : (_property$assetManage3 = _property$assetManage2.items) === null || _property$assetManage3 === void 0 ? void 0 : _property$assetManage3.filter(function (b) {
          return moment.utc(b.validFrom).year() === year && moment.utc(b.validTo).year() === year;
        })) !== null && _property$assetManage !== void 0 ? _property$assetManage : [];
        var budget = budgets.reduce(function (a, b) {
          return !a || moment.utc(b.createdAt).isAfter(moment.utc(a.createdAt)) ? b : a;
        }, undefined);
        if (budget) ids.add(budget.id);
      });
    });
    return Array.from(ids);
  });
  var budgetFilters = useQueryFilters({
    filters: [function () {
      return {
        matches: [{
          id: [Operator.In, budgetIds.value]
        }]
      };
    }]
  });
  var budgetRecordsQuery = useQuery(ResultsOpexBudgetRecordsDocument, function () {
    return {
      filters: budgetFilters.value.value.filters
    };
  }, function () {
    return {
      enabled: enabled.value && !propertiesOpexQuery.error.value && !propertiesOpexQuery.loading.value && !!properties.value.length
    };
  });
  var budgets = computed(function () {
    var _budgetRecordsQuery$r, _budgetRecordsQuery$r2, _budgetRecordsQuery$r3;
    return (_budgetRecordsQuery$r = (_budgetRecordsQuery$r2 = budgetRecordsQuery.result.value) === null || _budgetRecordsQuery$r2 === void 0 ? void 0 : (_budgetRecordsQuery$r3 = _budgetRecordsQuery$r2.assetManagementBudgets) === null || _budgetRecordsQuery$r3 === void 0 ? void 0 : _budgetRecordsQuery$r3.items) !== null && _budgetRecordsQuery$r !== void 0 ? _budgetRecordsQuery$r : [];
  });
  var opexCategoriesQuery = useOpexCategoriesQuery();
  var opexCategories = computed(function () {
    var _opexCategoriesQuery$;
    var arr = (_opexCategoriesQuery$ = opexCategoriesQuery.result.value) !== null && _opexCategoriesQuery$ !== void 0 ? _opexCategoriesQuery$ : [];
    var categoryId = variables.value.categoryId;
    if (categoryId) arr = arr.filter(function (c) {
      return c.id === categoryId;
    });
    return arr;
  });
  var categoryIds = computed(function () {
    return new Set(opexCategories.value.map(function (c) {
      return c.id;
    }));
  });
  var benchmarkOpexDataAverage = computed(function () {
    return getPropertiesAverageBenchmark(properties.value);
  });
  var getPropertyBenchmarkData = function getPropertyBenchmarkData(categories, property, columns) {
    var _property$assetManage4, _property$assetManage5, _property$assetManage6, _preferences$value2, _preferences$value$no, _preferences$value3;
    var _getPropertyArea = getPropertyArea(property, benchmarkOpexDataAverage.value.entityData.areaUnit),
      area = _getPropertyArea.area,
      areaUnit = _getPropertyArea.areaUnit;
    var opexData = {
      cells: property.categoryCells,
      entityData: {
        averageArea: area || 1,
        areaUnit: areaUnit,
        totalArea: area,
        totalTenancies: (_property$assetManage4 = (_property$assetManage5 = property.assetManagementTenancies) === null || _property$assetManage5 === void 0 ? void 0 : (_property$assetManage6 = _property$assetManage5.items) === null || _property$assetManage6 === void 0 ? void 0 : _property$assetManage6.length) !== null && _property$assetManage4 !== void 0 ? _property$assetManage4 : 0
      }
    };
    var categoriesData = getOpexCategoryBenchmarkData(columns, opexData, isBudgetBenchmark.value ? getPropertyBudgetBenchmark(property, categoryIds.value, budgets.value, benchmarkOpexDataAverage.value) : benchmarkOpexDataAverage.value, {
      includeZeroRows: (_preferences$value2 = preferences.value) === null || _preferences$value2 === void 0 ? void 0 : _preferences$value2.includeZeroRows,
      normaliseBy: (_preferences$value$no = (_preferences$value3 = preferences.value) === null || _preferences$value3 === void 0 ? void 0 : _preferences$value3.normaliseBy) !== null && _preferences$value$no !== void 0 ? _preferences$value$no : "area",
      periodType: periodType.value
    }, categories, true);
    return {
      property: property,
      categories: categoriesData,
      area: area
    };
  };
  var getRows = computed(function () {
    return function (rows) {
      var _arr$find;
      if (!opexCategories.value) return [];
      var categories = new Map();
      var propertyOpexBenchmarks = rows.map(function (property) {
        return getPropertyBenchmarkData(opexCategories.value, property, columns.value);
      });
      var _loop = function _loop() {
        var propertyCategoryData = propertyOpexBenchmarks[i];
        var _loop2 = function _loop2() {
          var _preferences$value5, _preferences$value6;
          var categoryData = propertyCategoryData.categories[c];
          var preferencesBenchmark = (_preferences$value5 = preferences.value) === null || _preferences$value5 === void 0 ? void 0 : _preferences$value5.benchmark;
          var group = getSetValue(categoryData.id, categories, function () {
            return {
              propertiesData: new Map(),
              id: categoryData.id,
              name: categoryData.name,
              currency: categoryData.currency,
              benchmark: {
                type: preferencesBenchmark === "budget.diff" ? "diffTotal" : "average",
                values: preferencesBenchmark === "budget.diff" ? categoryData.diff.map(function () {
                  return {
                    accumulated: 0,
                    accumulatedAnnualised: 0,
                    value: 0,
                    valueAnnualised: 0
                  };
                }) : categoryData.benchmark
              }
            };
          });
          if (((_preferences$value6 = preferences.value) === null || _preferences$value6 === void 0 ? void 0 : _preferences$value6.benchmark) === "budget.diff") {
            categoryData.diff.forEach(function (v, index) {
              var _prev$accumulated;
              var curr = group.benchmark.values[index];
              var prev = group.benchmark.values[index - 1];
              curr.value += (v.value || 0) * (propertyCategoryData.area / benchmarkOpexDataAverage.value.entityData.totalArea);
              curr.accumulated = curr.value + ((_prev$accumulated = prev === null || prev === void 0 ? void 0 : prev.accumulated) !== null && _prev$accumulated !== void 0 ? _prev$accumulated : 0);
            });
          }
          group.propertiesData.set(propertyCategoryData.property.id, categoryData);
        };
        for (var c = 0; c < propertyCategoryData.categories.length; c++) {
          _loop2();
        }
      };
      for (var i = 0; i < propertyOpexBenchmarks.length; i++) {
        _loop();
      }
      var arr = Array.from(categories.values());
      areaUnit.value = benchmarkOpexDataAverage.value.entityData.areaUnit;
      currency.value = (_arr$find = arr.find(function (r) {
        return !!r.currency;
      })) === null || _arr$find === void 0 ? void 0 : _arr$find.currency;
      return arr.filter(function (row) {
        var _preferences$value4;
        return ((_preferences$value4 = preferences.value) === null || _preferences$value4 === void 0 ? void 0 : _preferences$value4.includeZeroRows) || !isZeroOpexRow(row, preferences.value.periodRangeType);
      });
    };
  });
  var tableRows = computed(function () {
    return getRows.value(properties.value);
  });
  var loading = computed(function () {
    return propertiesOpexQuery.loading.value || opexCategoriesQuery.loading.value || budgetRecordsQuery.loading.value;
  });
  return {
    currency: currency,
    areaUnit: areaUnit,
    loading: loading,
    tableRows: tableRows,
    columns: columns,
    properties: properties,
    periodType: periodType
  };
};